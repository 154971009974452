$mat_primary: #faa559;
$mat_primary_light: #ffeede;

$mat_accent: #80d8db;
$mat_accent_light: #91d9da;
$mat_accent_light2: #f3fffd;
$mat_accent_light3: #b3f8fc;
$mat_accent_dark1: #449095;

$mat_warn: #e95656;
$mat_warn_light: #ffd2cf;
$mat_warn_dark: #f44336;

$pink: #ffacac;

$rating: #facd44;
$yellow: #f0d478;
$dark_yellow: #f3bc69;
$light_yellow: #fff9e8;
$light_yellow2: #fffdf5;
$light_yellow3: #fffbf0;

$black: #333333;
$black2: #4e4e4e;
$black3: #626262;
$light_black: #4e5258;
$dark_black: #000000;
$transparent_black: #ffffff00;
$transparent_black2: #00000040;

$white: #fff;
$light_white: #e3e3e3;
$light_white2: #f6f6f6;
$light_white3: #fefefe;
$light_white4: #ecf0f1;
$light_white5: #dcdcdc;
$light_white6: #f8f8f8;
$light_white7: #dbdbdb;
$light_white8: #f9f9fb;

$grey: #828282;
$grey2: #4f4f4f;
$grey3: #606f7b;
$grey4: #dee2e6;
$grey5: #f5f5f5;
$grey6: #959595;
$grey6: #7c7c7c;
$grey7: #a5a5a5;
$grey8: #bfbfbf;
$light_grey: #f2f2f2;
$light_grey2: #f6f6f6;
$light_grey3: #e0e0e0;
$light_grey4: #bcbcbc;
$light_grey5: #75757d;
$light_grey6: #78787f;
$light_grey7: #5e6260;
$light_grey8: #636362;
$light_grey9: #bdc3c7;
$light_grey_10: #c2c2c2;
$transparent_grey: #ffffffe6;

$disabled: #bdbdbd;
$light_border: #e8ebee;

$scroll_thumb: #e7e7e7;
$scroll_thumb2: #e0e5f3;
$scroll_thumb3: #c4c4c4;

$blue: #396aff;
$dark_blue: #314e97;
$light_blue: #f5f6fa;
$light_blue2: #aab2c8;
$light_blue3: #bfd3ff;
$light_blue4: #9aa8ba;
$light_blue5: #ced4da;
$purple: #6a93f5;
$violet: #acaaff;

$green: #6fcf97;
$green2: #4dc5af;
$light_green: #8cca66;
$light_green2: #f1fff7;
$light_green3: #f9fffb;
$light_green4: #deffec;
$light_green5: #f3fff4;
$light_green6: #aed2b2;
