@import "./@moppetto/Theming/theme";
@import "./@moppetto/Theming/colors";
@import url("https://fonts.googleapis.com/css2?family=Happy+Monkey&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import "/src/assets/scss/Material/material_custom_components";

* {
    font-family: "Poppins", sans-serif;
}

.happyMonkeyFont,
.profileCategory {
    font-family: "Happy Monkey", cursive !important;
    p {
        font-family: "Happy Monkey", cursive !important;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.bs-rating-star {
    font-size: 3rem;
    color: $mat_primary;
}

.bs-datepicker-body table td.week span {
    color: $mat_primary !important;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-head,
.bs-datepicker-head,
.bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover {
    background-color: $mat_primary !important;
    font-family: "Poppins", sans-serif;
}

.bs-datepicker-head .current {
    font-size: 1.5rem !important;
}

::selection {
    background-color: $mat_primary;
    color: $white;
}

.animatedAnchor {
    color: $blue !important;
    transition: all 0.5s ease-in-out !important;
    &:hover {
        text-decoration: underline !important;
        color: darken($mat_primary, 17%) !important;
    }
}

body {
    overflow-x: hidden;
    /* width */
    &::-webkit-scrollbar {
        width: 1rem;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 1rem;
        background: $light_white7;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $grey8;
        &:hover {
            background: $grey7;
        }
    }
}

// Bootstrap customized dropdown
.dropdown .dropdown-menu {
    -webkit-transition: all 0.32s;
    -moz-transition: all 0.32s;
    -ms-transition: all 0.32s;
    -o-transition: all 0.32s;
    transition: all 0.32s;
    display: block;
    overflow: hidden;
    opacity: 0;
    transform: translateX(-25%) scaleY(0);
    transform-origin: top;
}
.dropdown-menu.show {
    opacity: 1;
    transform: translateX(-25%) scaleY(1);
}

.rateIcons {
    .visually-hidden {
        display: none;
    }
}

.cdk-overlay-container {
    z-index: 999;
}

.imgWithBorder {
    border: solid lighten($mat_primary, 7%) 0.1rem;
}

html {
    font-size: 67%;
    @media (max-width: 1199px) {
        font-size: 57.5%;
    }
    @media (max-width: 991px) {
        font-size: 55.5%;
    }
    @media (max-width: 767px) {
        font-size: 52.5%;
    }
    @media (max-width: 575px) {
        font-size: 45.5%;
    }
    scroll-behavior: smooth;
}

.material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 200, "opsz" 40;
}
