@import "../CommonDesigns/commonDesign";
@import "../../../@moppetto/Theming/colors";

:host ::ng-deep mat-form-field {
    width: 100%;

    .mdc-text-field .mdc-floating-label {
        font-size: 1.4rem;
    }

    .mdc-text-field .mdc-floating-label--float-above {
        font-size: 1.8rem;
    }

    .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
        @include fontStyle($black, 1.5rem, 400);
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
    }

    .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
    }

    .mdc-text-field--filled.mdc-text-field--disabled {
        background-color: $light_grey3;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
        margin: 0;
        position: static;
    }

    .mat-mdc-form-field-subscript-wrapper {
        margin: -1.9rem 0 0 0;
        z-index: -1;
    }

    .mat-mdc-form-field-required-marker {
        color: $mat_warn !important;
    }
}

:host ::ng-deep mat-checkbox {
    margin-left: -1.125rem;

    label {
        margin-bottom: 0;
        margin-top: 0.25rem;
        @include fontStyle($grey, 1.3rem, 400);
    }

    .mat-mdc-checkbox-button .mdc-checkbox {
        padding-left: 0;
    }

    .mat-mdc-checkbox-checked label {
        color: $black;
    }
}

:host ::ng-deep .mat-mdc-checkbox-checked label {
    color: $black;
}

:host ::ng-deep mat-radio-button {
    margin-left: -1.125rem;

    label {
        cursor: pointer;
        margin-bottom: 0;
        margin-top: 0.25rem;
        padding-left: 0;
        @include fontStyle($grey, 1.2rem, 400);
    }
}

:host ::ng-deep .mat-mdc-radio-checked label {
    color: $black;
}

:host ::ng-deep mat-error {
    margin: 0;
    @include fontStyle($mat_warn, 1.15rem, 500);

    @media (max-width: 767px) {
        font-size: 1.4rem;
    }
}

::ng-deep .mat-mdc-option .mdc-list-item__primary-text {
    @include fontStyle($grey, 1.425rem !important, 400);
}

::ng-deep .mat-mdc-option-active .mdc-list-item__primary-text {
    @include fontStyle($mat_primary, 1.425rem !important, 400);
}

::ng-deep .mat-mdc-select-value {
    @include fontStyle($black, 1.5rem !important, 400);
}

::ng-deep .mat-mdc-menu-panel,
::ng-deep .mdc-menu-surface.mat-mdc-select-panel {
    max-height: 27rem !important;
    @include scrollBar(hidden, auto, 0.5rem, 0.5rem, 0rem, 0rem, 1rem, transparent, $scroll_thumb, 1rem);
}

.listViewCustomMenu {
    width: 100%;
    @include displayFlex(center, center);
    background-color: $white;
    border: $scroll_thumb solid 0.1rem;
    border-radius: 2rem;
    transition: all ease-in-out 0.7s;

    &:hover {
        box-shadow: 0 0 0.3rem $mat_primary;
        border-color: $mat_primary;
    }

    button {
        @include mat_custom_button($white, 100%, 1.8rem, 3rem, 2rem);
        @include fontStyle(darken($disabled, 17%), 1.425rem, 400);

        @media (max-width: 575px) {
            font-size: 1.6rem;
        }

        margin: 0;

        @media (max-width: 991px) {
            padding-top: 0.7rem !important;
            padding-bottom: 0.7rem !important;
            height: min-content !important;
        }
    }

    .listViewCustomMenuButtonWithImage {
        @include displayFlex(center, center);

        .selectedChildImages {
            &:not(:first-child) {
                margin-left: -1rem !important;
            }

            .childImg {
                width: 2.25rem;
                height: 2.25rem;
                border-radius: 100%;
                overflow: hidden;
                @include displayFlex(center, center);

                img {
                    // width: 2.25rem;
                    // height: 2.25rem;
                    height: 100%;
                    border-radius: 100%;
                    // border: $mat_primary solid 0.12rem;
                    background-color: $white;
                    // object-fit: cover;
                }
            }

            .selectedImg {
                width: 2rem;
                height: 2rem;
                margin-left: 1rem;

                &:nth-child(1) {
                    margin-left: 0 !important;
                }
            }
        }

        .countText {
            padding: 0.15rem 0 0 1.05rem;

            p {
                @include fontStyle($mat_primary, 1.425rem, 500);

                @media (max-width: 575px) {
                    font-size: 1.6rem;
                }
            }
        }
    }
}

::ng-deep .customize_list_view_mat_menu {
    min-width: none;
    max-width: none;
    width: 100%;
    text-align: center;

    .menu_texts {
        width: 100%;
        padding: 0.5rem 2rem;

        p {
            @include fontStyle($black, 1.425rem, 400);

            @media (max-width: 575px) {
                font-size: 1.6rem;
            }
        }
    }

    .selectImgLabelsOuter {
        @include displayFlex(flex-start, center);
        padding: 0.5rem 2rem;

        .selectImgLabels {
            border-radius: 100%;
            @include displayFlex(center, center);
            padding: 0.1rem;
            // border: solid $mat_primary 0.1rem;
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 2rem;
            overflow: hidden;

            img {
                height: 100%;
                // width: 100%;
                // object-fit: cover;
                border-radius: 100%;
            }
        }

        .selectImgLabelsText {
            p {
                @include fontStyle($black, 1.425rem, 400);

                @media (max-width: 575px) {
                    font-size: 1.6rem;
                }

                text-align: left;
            }
        }
    }
}

::ng-deep .customize_list_view_mat_menu.mat-mdc-menu-panel.mat-mdc-menu-panel {
    border-radius: 2rem !important;
}

.dropdown_type_date_view {
    width: 100%;
    @include displayFlex(center, center);
    background-color: $white;
    border: $scroll_thumb solid 0.1rem;
    border-radius: 2rem;
    transition: all ease-in-out 0.7s;
    padding: 0.8rem 1rem;

    &:hover {
        box-shadow: 0 0 0.3rem $mat_primary;
        border-color: $mat_primary;
        background-color: $light_grey;
    }

    p {
        @include fontStyle($black, 1.425rem, 400);

        @media (max-width: 575px) {
            font-size: 1.6rem;
        }
    }
}

:host ::ng-deep mat-slider {
    width: calc(100% - 4.5rem);
    margin-left: 2.25rem;

    @media (max-width: 575px) {
        width: calc(100% - 5rem);
        margin-left: 2.75rem;
    }

    .mdc-slider__track {
        .mdc-slider__track--inactive {
            border-radius: 0;
        }

        .mdc-slider__track--active {
            border-radius: 0;
        }
    }
}

:host ::ng-deep .custom_mat_bordered_select {
    mat-form-field {
        height: 4.5rem;

        @media (max-width: 1199px) {
            height: 5rem;
        }

        @media (max-width: 991px) {
            height: 5.5rem;
        }

        @media (max-width: 767px) {
            height: 5.75rem;
        }

        @media (max-width: 575px) {
            height: 6.5rem;
        }

        padding: 0;

        .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
            @include fontStyle($black, 1.4rem, 400);
        }

        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
            background-color: $white;
        }

        .mat-mdc-form-field-focus-overlay {
            background-color: $white;
        }
    }

    .mat-mdc-text-field-wrapper {
        height: none !important;
    }

    .mdc-text-field--filled .mdc-line-ripple::before {
        border: none !important;
    }

    .mdc-text-field--filled .mdc-line-ripple::after {
        border: none !important;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
        padding: 1rem !important;
        border-radius: 0;
    }

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
        padding: 0 !important;
    }

    .mdc-text-field {
        padding: 0;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        transition: all ease-in-out 0.7s;
        border-radius: 0 !important;

        &:hover,
        &:focus {
            box-shadow: 0 0 0.3rem $mat_primary !important;
            border-color: $mat_primary !important;
        }
    }

    .mdc-text-field--filled {
        border: solid 0.1rem $scroll_thumb !important;
        border-radius: 0 !important;
    }

    .mdc-list-item {
        font-size: 1rem !important;
    }

    button {
        padding: 0 !important;
        width: 2.5rem !important;
        height: 2.5rem !important;
        @include displayFlex(center, center);
        margin-top: -0.7rem;
        margin-right: 0.75rem;

        @media (max-width: 1199px) {
            margin-top: -1.1rem;
        }

        @media (max-width: 991px) {
            margin-top: -1.3rem;
        }

        @media (max-width: 767px) {
            margin-top: -1.5rem;
        }

        @media (max-width: 575px) {
            margin-top: -1.7rem;
        }
    }
}

:host ::ng-deep .mat_input_with_calendar_icon {

    .mat-mdc-form-field-icon-suffix,
    [dir="rtl"] .mat-mdc-form-field-icon-prefix {
        position: absolute;
        z-index: -2;
        pointer-events: none;
        right: 0.25rem;
        bottom: 3rem;
        padding: 0;

        @media (max-width: 767px) {
            bottom: 4rem;
        }

        @media (max-width: 575px) {
            bottom: 4.5rem;
        }

        mat-icon {
            position: absolute;
            pointer-events: none;
            right: 0;
            top: 0;
            padding: 0;
        }
    }

    input {
        padding-right: 3.7rem;
    }
}


.mat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

// this is the parent span angular inserts for icon inside mat-fab
::ng-deep .mat-fab .mat-button-wrapper {
    display: flex !important;
    justify-content: center !important;
}