// @mixin buttonDesign($fontSize, $fontWeight, $paddingTopBottom, $paddingLeftRight, $borderWidth, $borderRadius) {
//     button {
//         font-size: $fontSize;
//         font-weight: $fontWeight;
//         padding: $paddingTopBottom $paddingLeftRight;
//         border-width: $borderWidth;
//         border-radius: $borderRadius;
//         cursor: pointer;
//     }
// }

// @mixin classWisebuttonDesign($bgColor, $borderColor, $color, $outlineColor) {
//     background-color: $bgColor;
//     border-color: $borderColor;
//     color: $color;
//     &:focus {
//         outline: $outlineColor;
//         box-shadow: 0 0 0.27rem $bgColor;
//     }
// }

@mixin displayFlex($justifyContent, $alignItems) {
    display: flex;
    justify-content: $justifyContent;
    align-items: $alignItems;
}

@mixin fontStyle($color, $fontSize, $fontWeight) {
    color: $color;
    font-size: $fontSize;
    font-weight: $fontWeight;
}

@mixin scrollBar(
    $overflowX,
    $overflowY,
    $scrollbarHeight,
    $scrollbarWidth,
    $scrollbarTrackMarginTopBottom,
    $scrollbarTrackMarginLeftRight,
    $scrollbarTrackBorderRadius,
    $scrollbarTracBG,
    $scrollbarThumbColor,
    $scrollbarThumbBorderRadius
) {
    overflow-x: $overflowX;
    overflow-y: $overflowY;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        height: $scrollbarHeight;
        width: $scrollbarWidth;
    }

    &::-webkit-scrollbar-track {
        margin: $scrollbarTrackMarginTopBottom $scrollbarTrackMarginLeftRight;
        border-radius: $scrollbarTrackBorderRadius;
        background: $scrollbarTracBG;
    }

    &::-webkit-scrollbar-thumb {
        background: $scrollbarThumbColor;
        border-radius: $scrollbarThumbBorderRadius;
    }
}

@mixin roundedImg($width, $height) {
    width: $width;
    height: $height;
    border-radius: 100%;
    overflow: hidden;
    @include displayFlex(center, center);
    img {
        border-radius: 100%;
        height: 100%;
        overflow: hidden;
        // height: 100%;
        // object-fit: cover;
    }
}

@mixin editAndDeleteIcon($backgroundColor) {
    @include displayFlex(center, center);
    padding: 0.7rem;
    width: 4rem;
    height: 4rem;
    background-color: $backgroundColor;
    border-radius: 1rem;
    cursor: pointer;
    transition: all ease-in-out 0.7s;
    &:hover {
        background-color: darken($backgroundColor, 12%);
        transform: translateY(-0.25rem);
    }
    img {
        width: 100%;
    }
}

// @mixin saveAndCancelIconButtons($width, $marginTop, $marginBotom, $iconColor, $cancelBG, $saveBG) {
//     width: $width;
//     margin: $marginTop auto $marginBotom auto;
//     @include displayFlex(space-between, center);

//     .forCancel,
//     .forSave {
//         @include displayFlex(center, center);
//         height: 3.3rem;
//         width: 3.3rem;
//         border-radius: 100%;
//         cursor: pointer;
//         transition: all ease-in-out 0.7s;
//         &:hover {
//             transform: translateY(-0.25rem);
//         }
//         i {
//             @include fontStyle($iconColor, 1.8rem, 500);
//         }
//     }

//     .forCancel {
//         background-color: $cancelBG;
//         transition: all ease-in-out 0.7s;
//         &:hover {
//             background-color: darken($cancelBG, 12%);
//         }
//     }

//     .forSave {
//         background-color: $saveBG;
//         transition: all ease-in-out 0.7s;
//         &:hover {
//             background-color: darken($saveBG, 12%);
//         }
//     }
// }

@mixin mat_custom_button($bg, $width, $padding-top-bottom, $padding-left-right, $radius) {
    &:not(:disabled) {
        background-color: $bg;
    }
    padding: $padding-top-bottom $padding-left-right;
    width: $width;
    border-radius: $radius;
    letter-spacing: normal;
}

@mixin mat_custom_fab_button($bg, $widthHeight) {
    &:not(:disabled) {
        background-color: $bg;
    }
    width: $widthHeight;
    height: $widthHeight;
    @include displayFlex(center, center);
    letter-spacing: normal;
}

@mixin saveAndCancelMatIconButtons(
    $width,
    $marginTop,
    $marginBotom,
    $buttonHeightWidth,
    $cancelBG,
    $saveBG,
    $color,
    $fontSize,
    $fontWeight
) {
    width: $width;
    margin: $marginTop auto $marginBotom auto;
    @include displayFlex(space-between, center);
    button {
        box-shadow: none;
        mat-icon {
            height: $fontSize;
            width: $fontSize;
            @include fontStyle($color, $fontSize, $fontWeight);
        }
        transition: all ease-in-out 0.7s;
        &:hover {
            transform: translateY(-0.25rem);
        }
        @include displayFlex(center, center);
    }
    .tickBtn {
        @include mat_custom_fab_button($saveBG, $buttonHeightWidth);
    }
    .closeBtn {
        @include mat_custom_fab_button($cancelBG, $buttonHeightWidth);
    }
}
